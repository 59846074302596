import React from "react";
import styled from "@emotion/styled";
import JewelrySample from "../../images/jewelry-sample.jpeg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs/Breadcrumbs";
import { breakpoints } from "../const";
import AppCalendar from "../../common/components/AppCalendar";
import { Button } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";

const Container = styled(Grid)`
  margin-top: calc(10 * var(--unit));
  padding: unset;

  &.bread-crumbs {
    padding-left: calc(2 * var(--unit));
  }

  @media (min-width: ${breakpoints.mobile}) {
    padding: calc(2 * var(--unit));
  }
`;

const DetailedPaper = styled(Paper)`
  display: flex;
  padding: calc(3 * var(--unit));
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakpoints.mobile}) {
    flex-direction: row;
  }
`;

const ItemDetail = styled(Grid)`
  flex-grow: 2;
  max-width: calc(90 * var(--unit));
`;

const ItemImgContainer = styled.div`
  width: calc(40 * var(--unit));
  height: calc(40 * var(--unit));
  max-width: calc(70 * var(--unit));
  max-height: calc(70 * var(--unit));
  padding: calc(2 * var(--unit));
  min-width: calc(40 * var(--unit));

  img {
    width: 100%;
    height: 100%;
  }
`;

const StyledAccordion = styled(Accordion)`
  border: unset;
`;

const LegendAvailable = styled.div`
  width: calc(3 * var(--unit));
  height: calc(3 * var(--unit));
  border-radius: 50%;
  background-color: var(--color-date-available);
`;

const LegendUnavailable = styled.div`
  width: calc(3 * var(--unit));
  height: calc(3 * var(--unit));
  border-radius: 50%;
  border: 1px solid lightgray;
`;

const RentButton = styled(Button)`
  margin: var(--unit) 0;
`;

interface Props {
  goBackToMain: () => void;
}

const ItemDetails = ({ goBackToMain }: Props) => {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" onClick={goBackToMain}>
      Jewelry List
    </Link>,
    <Typography key="3" color="text.primary">
      GEMSTONE DIAMOND HEART
    </Typography>,
  ];

  return (
    <Container
      container
      display="flex"
      flexDirection="column"
      gap="calc(2 * var(--unit))"
    >
      <Stack spacing={2}>
        <Breadcrumbs
          className="bread-crumbs"
          separator="›"
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <DetailedPaper variant="outlined">
        <ItemImgContainer>
          <img alt="" src={JewelrySample} />
        </ItemImgContainer>
        <ItemDetail>
          <Typography
            variant="h4"
            fontWeight={700}
            fontFamily="Shoogie Regular"
          >
            GEMSTONE DIAMOND HEART
          </Typography>
          <Typography variant="h6">Brand Here</Typography>
          <RentButton
            variant="contained"
            fullWidth
            size="large"
            endIcon={<DiamondIcon />}
          >
            Rent this jewelry
          </RentButton>
          <Grid container display="flex" flexDirection="column">
            <StyledAccordion variant="outlined">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1-content"
              >
                <Typography fontWeight={600}>Availability</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AppCalendar />
                <Grid
                  display="flex"
                  columnGap="calc(3 * var(--unit))"
                  alignItems="center"
                >
                  <Grid
                    display="flex"
                    columnGap="var(--unit)"
                    alignItems="center"
                  >
                    <LegendUnavailable />
                    <span>Unavailable</span>
                  </Grid>
                  <Grid
                    display="flex"
                    columnGap="var(--unit)"
                    alignItems="center"
                  >
                    <LegendAvailable />
                    <span>Available</span>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion variant="outlined" defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1-content"
              >
                <Typography fontWeight={600}>Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                vulputate felis id porta dignissim. Pellentesque et hendrerit
                elit. Aenean tristique purus neque, et iaculis ex aliquam vel.
                Cras vitae mauris vel diam faucibus aliquam. Nam pretium, quam
                non vestibulum bibendum, ipsum eros mollis lorem, ut egestas
                augue lacus vitae nunc. Etiam interdum, ipsum nec efficitur
                placerat, felis ex lacinia leo, nec varius odio libero eget
                lorem. Pellentesque dui nibh, efficitur vel tempor id, fringilla
                eget lorem. Proin quis dui at ligula fringilla imperdiet eu eu
                mauris. Nam fringilla venenatis egestas. Morbi non turpis purus.
                Duis risus lorem, condimentum non aliquam aliquet, molestie sit
                amet odio. Orci varius natoque penatibus et magnis dis
                parturient montes, nascetur ridiculus mus. Sed tempor nunc
                risus, et aliquet ante mattis at. Curabitur hendrerit quam in
                semper finibus. Nunc tempus commodo nisi in cursus. Vivamus
                porttitor at lorem et tempus. Maecenas fermentum enim odio.
                Nullam dignissim libero velit, id consequat velit eleifend vel.
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion variant="outlined">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1-content"
              >
                <Typography fontWeight={600}>Deposit Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                vulputate felis id porta dignissim. Pellentesque et hendrerit
                elit. Aenean tristique purus neque, et iaculis ex aliquam vel.
                Cras vitae mauris vel diam faucibus aliquam. Nam pretium, quam
                non vestibulum bibendum, ipsum eros mollis lorem, ut egestas
                augue lacus vitae nunc. Etiam interdum, ipsum nec efficitur
                placerat, felis ex lacinia leo, nec varius odio libero eget
                lorem. Pellentesque dui nibh, efficitur vel tempor id, fringilla
                eget lorem. Proin quis dui at ligula fringilla imperdiet eu eu
                mauris. Nam fringilla venenatis egestas. Morbi non turpis purus.
                Duis risus lorem, condimentum non aliquam aliquet, molestie sit
                amet odio. Orci varius natoque penatibus et magnis dis
                parturient montes, nascetur ridiculus mus. Sed tempor nunc
                risus, et aliquet ante mattis at. Curabitur hendrerit quam in
                semper finibus. Nunc tempus commodo nisi in cursus. Vivamus
                porttitor at lorem et tempus. Maecenas fermentum enim odio.
                Nullam dignissim libero velit, id consequat velit eleifend vel.
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion variant="outlined">
              <AccordionSummary
                expandIcon={<AddIcon />}
                aria-controls="panel1-content"
              >
                <Typography fontWeight={600}>
                  Delivery & Return Instruction
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                vulputate felis id porta dignissim. Pellentesque et hendrerit
                elit. Aenean tristique purus neque, et iaculis ex aliquam vel.
                Cras vitae mauris vel diam faucibus aliquam. Nam pretium, quam
                non vestibulum bibendum, ipsum eros mollis lorem, ut egestas
                augue lacus vitae nunc. Etiam interdum, ipsum nec efficitur
                placerat, felis ex lacinia leo, nec varius odio libero eget
                lorem. Pellentesque dui nibh, efficitur vel tempor id, fringilla
                eget lorem. Proin quis dui at ligula fringilla imperdiet eu eu
                mauris. Nam fringilla venenatis egestas. Morbi non turpis purus.
                Duis risus lorem, condimentum non aliquam aliquet, molestie sit
                amet odio. Orci varius natoque penatibus et magnis dis
                parturient montes, nascetur ridiculus mus. Sed tempor nunc
                risus, et aliquet ante mattis at. Curabitur hendrerit quam in
                semper finibus. Nunc tempus commodo nisi in cursus. Vivamus
                porttitor at lorem et tempus. Maecenas fermentum enim odio.
                Nullam dignissim libero velit, id consequat velit eleifend vel.
              </AccordionDetails>
            </StyledAccordion>
          </Grid>
        </ItemDetail>
      </DetailedPaper>
    </Container>
  );
};

export default ItemDetails;
