import React from "react";
import styled from "@emotion/styled";
import { Grid, Paper } from "@mui/material";
import JewelrySample from "../../images/jewelry-sample.jpeg";
import Typography from "@mui/material/Typography";

const Container = styled(Paper)`
  width: calc(30 * var(--unit));
  height: calc(35 * var(--unit));
  padding: calc(2 * var(--unit));
`;

const ItemImage = styled.img`
  width: 100%;
  height: calc(25 * var(--unit));
  object-fit: contain;
`;

interface Props {
  openDetails: () => void;
}

const JewelryItem = ({ openDetails }: Props) => {
  return (
    <Container variant="outlined" onClick={openDetails}>
      <Grid container display="flex" flexDirection="column" height="100%">
        <ItemImage alt="" src={JewelrySample} />
        <Grid
          container
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={2}
        >
          <Grid
            container
            display="flext"
            flexDirection="column"
            alignItems="center"
          >
            <Typography fontStyle="Shoogie Regular" fontWeight={600}>
              GEMSTONE DIAMOND HEART
            </Typography>
            <Typography fontStyle="Shoogie Regular" fontWeight={500}>
              Swarovski
            </Typography>
          </Grid>
          <Typography fontStyle="Futura Std Light" fontSize={15}>
            <b>Rent price:</b> 300
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JewelryItem;
