import React, { useState } from "react";
import styled from "@emotion/styled";
import { AppHeader } from "../../common/components";
import JewelryItem from "./JewelryItem";
import ItemDetails from "./ItemDetails";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`;

const ItemsContainer = styled.div`
  margin-top: calc(10 * var(--unit));
  display: flex;
  flex-wrap: wrap;
  gap: calc(3 * var(--unit));
  padding: calc(2 * var(--unit));
  justify-content: center;
  align-items: flex-start;
`;

const Shop = () => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <Container>
      <AppHeader />
      {showDetails ? (
        <ItemDetails goBackToMain={() => setShowDetails(false)} />
      ) : (
        <ItemsContainer>
          {Array.from({ length: 19 }).map((_, i) => (
            <JewelryItem key={i} openDetails={() => setShowDetails(true)} />
          ))}
        </ItemsContainer>
      )}
    </Container>
  );
};

export default Shop;
