import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import createTheme from "@mui/material/styles/createTheme";
import { ThemeProvider } from "@mui/material";
import { Shop } from "./pages";

// light - f0ebe8
// darker than light - a99f90
// primary - 3c3b37
// secondary - 878682
// darker primary - 595854

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3b37",
    },
    secondary: {
      main: "#f0ebe8",
    },
  },
  typography: {
    fontFamily: [
      "Futura Std Light",
      "Shoogie Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Shop />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
