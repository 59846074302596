import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import styled from "@emotion/styled";

const Container = styled.div`
  .MuiPickersDay-dayWithMargin {
    background-color: var(--color-date-available);

    &.Mui-disabled {
      background-color: unset;
      color: lightgray;
    }
  }
`;

const AppCalendar = () => {
  const disabledDates = [
    DateTime.local(2024, 5, 28),
    DateTime.local(2024, 6, 1),
    DateTime.local(2024, 6, 15),
  ];

  const shouldDisableDate = (date: DateTime) => {
    return disabledDates.some((disabledDate) =>
      disabledDate.hasSame(date, "day"),
    );
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateCalendar
          minDate={DateTime.now()}
          readOnly
          loading={false}
          shouldDisableDate={shouldDisableDate}
        />
      </LocalizationProvider>
    </Container>
  );
};

export default AppCalendar;
